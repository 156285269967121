<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const loginGuard = useLoginGuard();

const handleClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDailyWheel");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div class="wrapper">
		<NuxtImg
			class="background"
			src="/nuxt-img/wheel/banner-wheel.jpg"
			alt="banner-wheel"
			quality="100"
			format="webp"
			loading="lazy"
		/>
		<div class="wheel-container">
			<div v-for="lamp in 5" :key="lamp" :class="['lamp', `lamp--${lamp}`]" />
		</div>
		<div class="content">
			<AText variant="tempe" :modifiers="['semibold']">
				<span>{{ t("Wheel of Fortune") }}</span>
			</AText>
			<AText variant="taipei" :modifiers="['semibold']" class="label">
				<span>{{ t("Spin the Wheel everyday to collect your free coins") }}</span>
			</AText>
			<AAnimationCustomByScroll animationClass="blink" transitionDelay="2s" class="btn-wrapper">
				<AButton variant="primary" class="btn" size="lg" @click="handleClick">
					<AText variant="toledo" :modifiers="['medium']">
						{{ t("Spin") }}
					</AText>
				</AButton>
			</AAnimationCustomByScroll>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 20px rgba(253, 216, 53, 0.8);
	}
	100% {
		transform: scale(1.2);
		box-shadow: 0 0 15px 10px rgba(253, 216, 53, 1);
	}
}

.wrapper {
	width: 100%;
	height: 50%;
	border-radius: 24px;
	border: 2px solid var(--cordoba);
	display: flex;
	padding: 24px;
	position: relative;
	overflow: hidden;
}

.content {
	max-width: 320px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	position: relative;
	z-index: 1;
}

.label {
	margin-top: 4px;
}

.background {
	height: 100%;
	min-width: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
}

.wheel-container {
	position: absolute;
	top: 0;
	right: 0;
	width: 190px;
	height: 100%;

	.lamp {
		position: absolute;
		width: 4px;
		height: 4px;
		border-radius: 50%;

		&--1 {
			left: 9px;
			top: 179px;
			animation: pulse 1s infinite alternate;
		}

		&--2 {
			left: 14px;
			top: 134px;
			animation: pulse 1s infinite 1s alternate;
		}

		&--3 {
			left: 32px;
			top: 93px;
			animation: pulse 1s infinite alternate;
		}

		&--4 {
			left: 65px;
			top: 60px;
			animation: pulse 1s infinite 1s alternate;
		}

		&--5 {
			left: 153px;
			top: 38px;
			animation: pulse 1s infinite alternate;
		}
	}
}

.btn-wrapper {
	margin-top: auto;
}

.btn {
	width: 165px;
}

.quest-link {
	margin: 0 auto;

	&:hover {
		cursor: pointer;
	}
}
</style>
